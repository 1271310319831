export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d’inscription"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat d’expertise Genre et Inclusion en Santé"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de résidence"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email valide"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme obtenu le plus élevé"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme le plus élevé"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement vos expériences professionnelles pertinentes pour le programme (300 mots maximum)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre ma candidature"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a été soumise avec succès. Nous vous contacterons pour confirmer votre pré-sélection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site web"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat d’expertise Genre et Inclusion en Santé"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développez une expertise avérée sur les enjeux du genre et de l’inclusion dans le domaine de la santé, avec notre programme à distance de certification professionnelle !"])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées "])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme vous permet d’acquérir les compétences essentielles pour intervenir dans les projets ou politiques de santé, en y intégrant une approche inclusive centrée sur la compréhension et la réduction des disparités liées au genre. Il vise à faire de vous, des expert(e)s qui pourront valoriser leurs savoirs au sein d'équipes pluridisciplinaires dans les organisations internationales, les organismes humanitaires, les projets de santé publique et les institutions sanitaires."])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notion de genre"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les concepts généraux liés à la notion de genre et leurs déterminants"])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre et inclusion sociale"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre les inégalités sociales liées au genre et les facteurs influençant l’évolution des relations homme-femme"])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre et santé"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distinguer les déterminants de la santé et discuter leurs liens avec la notion de genre"])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de genre"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réaliser une analyse exhaustive de genre dans les programmes ou politiques de santé publique"])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration du genre"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrer l’approche genre dans la conception et l’exécution des projets et politiques de santé publique"])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violences basées sur le genre"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les fondements des violences basées sur le genre et développer des stratégies de lutte efficaces y compris en situation crise sanitaire ou humanitaire"])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publics cibles & pré-requis"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels de la santé"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins, chirurgiens, infirmiers, sages-femmes, pharmaciens, paramédicaux."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres publics cibles"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialiste en genre et inclusion, gestionnaires de projets de santé, spécialistes en santé publique, spécialistes en santé communautaire, agents de développement communautaire, professionnels du secteur humanitaire, personnels d’organisations à but non lucratif, activistes et travailleurs sociaux, acteurs de la santé publique, etc."])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens pédagogiques"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est basé sur une méthode pédagogique d’approche par compétences et s’insère parfaitement dans votre agenda sans bousculer votre vie professionnelle ou personnelle"])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours sont dispensés à temps partiel, exclusivement à distance, en mode asynchrone (cours pré-enregistrés) et synchrone (classes virtuelles)."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le comité pédagogique de ce programme est composé de professeurs des universités et d’experts internationaux spécialisés sur les thématiques de genre et d’inclusion sociale en santé."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 heures de cours."])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du 10 août au 10 septembre 2024"])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin du programme, l’étudiant(e) est soumis(e) à une épreuve de contrôle de connaissance en ligne au format QCM."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme est sanctionné par un certificat professionnel accrédité en « expertise genre et inclusion en santé », qui est délivré aux étudiant(e)s ayant obtenu une note supérieure ou égale à 60% au terme du contrôle des connaissances."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du Programme"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’inscription"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibilité de paiements par tranches*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les droits d’inscription à la formation s’élèvent à"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription n’est considérée comme définitive qu’après règlement de ce montant auprès de l’organisme de formation médicale continue REMA, à condition que votre candidature soit acceptée. La date limite pour s’inscrire au programme est le 09 août 2024. Places limitées !"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les places disponibles étant limitées pour ce programme de certification, seules les candidatures pertinentes seront retenues. Les candidats sélectionnés seront contactés par nos équipes afin de finaliser leur inscription. Merci."])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
}